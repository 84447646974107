<template>
  <div class="Body">
    <ModalCallConfirmation
      :isDisplayModalConfirmCall="isDisplayModalConfirmCall"
      :trigger="trigger"
      :name="name"
      :id="id"
      @closeModal="isDisplayModalConfirmCall = false"
    />
    <ModalBody v-if="showModal">
      <button class="buttonGray" @click="(event) => onChangeShowModal(event)">
        Cancelar
      </button>
      <button
        :loading="loadingFinish"
        class="buttonGreen"
        @click="() => onChangeFinishCall(finishCallType)"
      >
        {{ loadingFinish ? "Cargando..." : "Aceptar" }}
      </button>
    </ModalBody>
    <DeriveModalDerivate v-if="showModalDerivate">
      <p class="textContentModal">{{  currentTurn.derivationMsg }}</p>
      <button
        :loading="loadingFinish"
        class="buttonGreen mt-4"
        @click="() => onCloseModalDerivate(event)"
      >
        Aceptar
      </button>
    </DeriveModalDerivate>
    <derive-modal ref="deriveModal">

    </derive-modal>
    <!-- new -->
    <ul class="Table__Body" v-if="currentTurn">
      <p class="starHour"></p>
      <li  class="Table__Body--Item">
        <div class="ContainerDropdown">
          <button class="Banner Table__Body--text" @click="toggleShowDrop()">
            <span >En atención</span>
            <svg data-v-7e3766b0="" width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg" class="IconBanner IconBannerRotated">
                <path data-v-7e3766b0="" d="M9.77988 0.229439C9.6389 0.0825295 9.44771 0 9.24837 0C9.04902 0 8.85783 0.0825295 8.71685 0.229439L4.99546 4.10846L1.27408 0.229439C1.13229 0.086693 0.94238 0.0077061 0.745262 0.00949156C0.548143 0.011277 0.359584 0.0936919 0.220195 0.238985C0.0808067 0.384278 0.00174141 0.580825 2.86102e-05 0.786293C-0.00168419 0.991761 0.0740929 1.18971 0.211038 1.33751L4.46394 5.77056C4.60492 5.91747 4.79611 6 4.99546 6C5.19481 6 5.386 5.91747 5.52698 5.77056L9.77988 1.33751C9.92082 1.19055 10 0.991266 10 0.783473C10 0.57568 9.92082 0.376394 9.77988 0.229439Z"
                fill="#30B365"></path>
            </svg>
          </button>
            <div   v-click-outside="closeShowDrop"
            
            v-if="showDrop"
            class="DropdownBox animate__animated animate__fadeIn"
            :class="
              showDrop
                ? 'AttDropdown'
                : 'AbsentDropdown'"
              >
              <h6 class="DropdownBox__title">Cambiar estado a:</h6>
              <button
                  v-for="(item, index) in buttonsAtention"
                  :key="index"
                  class="Banner Table__Body--text ButtonDropdown"
                  :style="bannerStylesWithoutCall(item.Estado).styles"
                  @click="() => atentionButtonsHandler(item)"
                >
                  <span>{{ item.Estado }}</span>
                  <span class="MiniSpinner__Container" v-if="item.loading"
                    ><MiniSpinner
                  /></span>
            </button>
          </div>
        </div>
        <p class="Table__Body--text">
          {{ currentTurn?.customer?.firstName }} {{ currentTurn?.customer?.lastName }}
        </p>
        <p class="Table__Body--text">
          {{ currentTurn?.customer?.dni }}
        </p>
        <p class="Table__Body--text">
          {{ toDate(currentTurn?.callTimeStamp) }}
        </p>
        <p class="Table__Body--text">
          <Counter :endDate="currentTurn?.callTimeStamp"></Counter>
        </p>
        <p class="Table__Body--text">
          {{ currentTurn?.letter }}{{ currentTurn?.number }}
          <span @click="(event) => onChangeShowModalDerivate()" v-if="currentTurn?.derivationMsg"><i aria-hidden="true" class="v-icon notranslate mdi mdi-bell theme--light" style="font-size: 27px;"></i></span>
        </p>
      </li>
    </ul>
    <!--/new -->
    <!-- <ul
      class="Table__Body"
      v-for="(bodyItem, index) in bodyParsed"
      :key="index"
    >
      <p class="starHour">{{ toAMPMDate(bodyItem[0]) }}</p>
      <li
        class="Table__Body--Item"
        v-for="bodyItem2 in bodyItem[1]"
        :key="bodyItem2.id"
      >
        <div class="ContainerDropdown">
          <button
            :disabled="callData && bodyItem2.Estado != 'En atención'"
            class="Banner Table__Body--text"
            :style="bannerStyles(bodyItem2).styles"
            @click="(event) => bodyItem2.Estado == 'Llamar' ? onShowModalConfirmCall(event, bodyItem2) : onClickStatus(event, bodyItem2)"
          >
            <span>
              {{
                bodyItem2.Estado == "SIN TURNO" ? "Llamar" : bodyItem2.Estado
              }}
            </span>
            <svg
              v-if="bannerStyles(bodyItem2).icon"
              class="IconBanner"
              :class="bodyItem2.showDropdown && 'IconBannerRotated'"
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.77988 0.229439C9.6389 0.0825295 9.44771 0 9.24837 0C9.04902 0 8.85783 0.0825295 8.71685 0.229439L4.99546 4.10846L1.27408 0.229439C1.13229 0.086693 0.94238 0.0077061 0.745262 0.00949156C0.548143 0.011277 0.359584 0.0936919 0.220195 0.238985C0.0808067 0.384278 0.00174141 0.580825 2.86102e-05 0.786293C-0.00168419 0.991761 0.0740929 1.18971 0.211038 1.33751L4.46394 5.77056C4.60492 5.91747 4.79611 6 4.99546 6C5.19481 6 5.386 5.91747 5.52698 5.77056L9.77988 1.33751C9.92082 1.19055 10 0.991266 10 0.783473C10 0.57568 9.92082 0.376394 9.77988 0.229439Z"
                :fill="bannerStyles(bodyItem2).icon"
              />
            </svg>
            <span class="MiniSpinner__Container" v-if="bodyItem2.loading"
              ><MiniSpinner
            /></span>
          </button>
          <div
            class="DropdownBox animate__animated animate__fadeIn"
            v-if="bodyItem2.showDropdown"
            :id="bodyItem2.id"
            :class="
              bodyItem2.Estado === 'En atención'
                ? 'AttDropdown'
                : 'AbsentDropdown'
            "
            @click="(event) => event.stopPropagation()"
          >
            <h6 class="DropdownBox__title">Cambiar estado a:</h6>
            <div
              class="ButtonContainer"
              v-if="bodyItem2.Estado === 'En atención'"
            >
              <button
                v-for="(item, index) in buttonsAtention"
                :key="index"
                class="Banner Table__Body--text ButtonDropdown"
                :style="bannerStylesWithoutCall(item.Estado).styles"
                @click="() => atentionButtonsHandler(item)"
              >
                <span>{{ item.Estado }}</span>
                <span class="MiniSpinner__Container" v-if="item.loading"
                  ><MiniSpinner
                /></span>
              </button>
            </div>
            <div class="ButtonContainer" v-else>
              <button
                v-for="(item, index) in buttonsAbsent"
                :key="index"
                class="Banner Table__Body--text ButtonDropdown"
                :style="bannerStyles(item).styles"
                @click="() => absentButtonsHandler(item, bodyItem2)"
              >
                <span>{{ item.Estado }}</span>
                <span class="MiniSpinner__Container" v-if="item.loading"
                  ><MiniSpinner
                /></span>
              </button>
            </div>
          </div>
        </div>
        <p class="Table__Body--text" :class="disabledText(bodyItem2.Estado)">
          {{ bodyItem2.HoraCita }}
        </p>
        <p
          class="Table__Body--text nameText"
          :title="bodyItem2.Nombre"
          :class="disabledText(bodyItem2.Estado)"
        >
          {{ bodyItem2.Nombre || checkExtra(bodyItem2.ExtraFields, 'Nombre') }}
        </p>
        <p
          class="Table__Body--text cedulaText"
          :title="bodyItem2.NumeroID"
          :class="disabledText(bodyItem2.Estado)"
        >
          {{ bodyItem2.NumeroType ? bodyItem2.NumeroID : checkExtra(bodyItem2.ExtraFields, 'NumeroID') }}
        </p>
        <p
          class="Table__Body--text"
          :class="
            (bodyItem2.Estado === 'Atendido' ||
              bodyItem2.Estado === 'No asistió') &&
            disabledText(bodyItem2.Estado)
          "
        >
          {{ bodyItem2.HoraLlegada ? bodyItem2.HoraLlegada : "-" }}
        </p>
        <p
          class="Table__Body--text"
          :class="
            (bodyItem2.Estado === 'Atendido' ||
              bodyItem2.Estado === 'No asistió') &&
            disabledText(bodyItem2.Estado)
          "
        >
          {{ bodyItem2.HoraAtendido || "-" }}
        </p>
        <p
          class="Table__Body--text"
          :class="
            (bodyItem2.Estado === 'Atendido' ||
              bodyItem2.Estado === 'No asistió') &&
            disabledText(bodyItem2.Estado)
          "
        >
          {{
            bodyItem2.Estado != "Llamar" && bodyItem2.Estado != "En atención"
              ? bodyItem2.HoraFinalizado
                ? bodyItem2.HoraFinalizado
                : "-"
              : "-"
          }}
        </p>
        <p class="Table__Body--text" :class="disabledText(bodyItem2.Estado)">
          {{ JSON.stringify(bodyItem2) }}
        </p>
       
      </li>
    </ul> -->
    <div
      class="noFound"
      v-if="
        bodyParsed.length < 1 && !getThemeMode.isLoading && !loadingMainTurns
      "
    >
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapState, mapGetters } from "vuex";
import Vue from 'vue' 
// import _ from "lodash";

Vue.directive('click-outside',{
  bind: function (el, binding, vnode) {
      el.eventSetDrag = function () {
          el.setAttribute('data-dragging', 'yes');
      }
      el.eventClearDrag = function () {
          el.removeAttribute('data-dragging');
      }
      el.eventOnClick = function (event) {
          var dragging = el.getAttribute('data-dragging');
          // Check that the click was outside the el and its children, and wasn't a drag
          if (!(el == event.target || el.contains(event.target)) && !dragging) {
              // call method provided in attribute value
              vnode.context[binding.expression](event);
          }
      };
      document.addEventListener('touchstart', el.eventClearDrag);
      document.addEventListener('touchmove', el.eventSetDrag);
      document.addEventListener('click', el.eventOnClick);
      document.addEventListener('touchend', el.eventOnClick);
  }, unbind: function (el) {
      document.removeEventListener('touchstart', el.eventClearDrag);
      document.removeEventListener('touchmove', el.eventSetDrag);
      document.removeEventListener('click', el.eventOnClick);
      document.removeEventListener('touchend', el.eventOnClick);
      el.removeAttribute('data-dragging');
  },
});

export default {
  name: "TableBody",

  data() {
    return {
      disabledAll: false,
      showModal: false,
      showModalDerivate: false,
      selectedEstado: null,
      id: "",
      name: "",
      finishCallType: null,
      loadingFinish: false,
      trigger: () => {},
      isDisplayModalConfirmCall: false,
      showDrop: false    
    };
  },

  components: {
    MiniSpinner: () => import("./MiniSpinner.vue"),
    ModalBody: () => import("./ModalBody.vue"),
    deriveModal: () => import("./DeriveModal.vue"),
    DeriveModalDerivate: () => import("./ModalBodyDerivate.vue"),
    ModalCallConfirmation: () => import("./ModalCallConfirmation.vue"),
    Counter: () => import("./Counter.vue")
  },

  mounted() {
    window.onclick = () => {
      if (this.body.some((item) => item.showDropdown) && !this.disabledAll) {
        this.onCleanBody();
      }
    };
  },

  watch: {
    // currentDate: {
    //   immediate: true,

    //   handler () {
    //     setInterval(() => { 
    //       this.currentDate = moment()
    //     }, 1000)
    //     // const endDateTimeObj = DateTime.fromISO(endDateTimeStr)

    //     // if (this.timer) {
    //     //   clearInterval(this.timer)
    //     // }

    //     // this.timer = setInterval(() => {
    //     //   this.now = DateTime.local()

    //     //   if (this.now > endDateTimeObj) {
    //     //     this.now = endDateTimeObj
    //     //     clearInterval(this.timer)
    //     //   }
    //     // }, 1000)
    //   }
    // } ,
    disabledAll(val) {
      document.querySelectorAll("button").forEach(($button) => {
        $button.disabled = val;
      });
    },
  },

  computed: {
    ...mapGetters(["getThemeMode"]),
    ...mapState({
      body: (state) => state.doctorPanel.body,
      buttonsAtention: (state) => state.doctorPanel.buttonsAtention,
      buttonsAbsent: (state) => state.doctorPanel.buttonsAbsent,
      pollingDataLoading: (state) => state.pollingWorkplaceSilver.loadingP,
      pollingData: (state) => state.pollingWorkplaceSilver.polling,
      callData: (state) => state.loggedUserSilver.callData,
      loggedData: (state) => state.loggedUserSilver.loggedUserData,
      cupsCodes: (state) => state.doctorPanel.cupsCodes,
      loadingMainTurns: (state) => state.doctorPanel.loadingMainTurns,
      turns: (state) => state.doctorPanel.turns,
    }),
    currentTurn() {
      return this.loggedData.worker?.actualTurn
    },
    currentDate() {
      return moment()
    },
    bodyParsed() {

      console.log("LOGGED DATA", this.loggedData.worker?.actualTurn)

      // const finalHours = {};
      // // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      // let body = this.turns.filter((b) => b.HoraAtendido);

      // // same body data combined
      // body = body.map((lb) => {
      //   let turnB = this.body
      //     .filter((f) => this.cupsCodes.includes(f.Atencion?.code))
      //     .find((b) => b.Turno?.turn_id == lb.Turno?.turn_id);
      //   return { ...lb, ...turnB };
      // });

      // // merge arrays
      // body = [...new Set([...this.body, ...body])];
      // body = _.uniqBy(body, function (e) {
      //   return e.id;
      // });

      // body = body.map((b) => {
      //   // Check service. If service exist then is from an appointment
      //   if (!b.Servicio || (b.Servicio && typeof b.Servicio != "object")) {
      //     b.HoraCita = "SIN CITA";
      //     b.HoraCitaGrilla = b.HoraLlegada;
      //   }
      //   b.NumeroID = b.NumeroType + ' ' + b.NumeroID;
      //   return b;
      // });

      // // filter call ended
      // body = body.filter((t) => {
      //   if (t.Estado == "Atendido" || t.Estado == "En atención") {
      //     if (t.Asesor == this.loggedData.id) return t;
      //   } else return t;
      // });

      // body.map((item) => {
      //   // const key = item.HoraCita.split(":")[0];
      //   const key = item.HoraCitaGrilla.split(":")[0];
      //   if (finalHours[key]) {
      //     finalHours[key].push(item);
      //     finalHours[key].sort(this.sortBody);
      //   } else finalHours[key] = [item];
      // });

      // const result = Object.entries(finalHours).sort();

      // if(this.body.length > 0){
      //   if (this.cupsCodes.length > 0) {
      //     return result;
      //   } else {
      //     return [];
      //   }
      // } else return result;

      
      return []
    },
  },

  methods: {
    ...mapActions([
      "onChangeBody",
      "onChangeButtonsAtention",
      "onChangeButtonsAbsent",
      "callTurnActionById",
      "pollingWorkplaceGetAction",
      "finishTurnAction",
      "revokeTurnAction",
      "resetCallData",
      "reCallTurnAction",
      "absentAction",
      "onChangeTurns",
      "reloadLoggedUserAction",
      "handlerDerivableQueueGroups"
    ]),
    toggleShowDrop() {
      this.showDrop = !this.showDrop
    },  
    closeShowDrop() {
      this.showDrop = false
    },
    onShowDrop() {
      this.showDrop = true
    },
    timeBeen(date) {
      let now = this.currentDate //todays date
      let end = moment(date); // another date
      let duration = moment(now.diff(end)).format('mm:ss');
      
      return duration
    },
    onShowModalConfirmCall(event, bodyItem2) {
      if(this.callData && this.callData.code) return;
      this.isDisplayModalConfirmCall = true;
      this.name = bodyItem2.Nombre;
      this.id = bodyItem2.NumeroID;
      this.trigger = () => this.onClickStatus(event, bodyItem2);
    },

    sortBody(itemA, itemB) {
      const itemBHCS = moment(
        moment().format("YYYY/MM/DD") + " " + itemB.HoraCitaGrilla
      );
      const itemAHCS = moment(
        moment().format("YYYY/MM/DD") + " " + itemA.HoraCitaGrilla
      );

      if (moment().diff(itemAHCS) - moment().diff(itemBHCS) > 0) {
        return -1;
      }
      if (moment().diff(itemAHCS) - moment().diff(itemBHCS) <= 0) {
        return 1;
      }
      return 0;
    },

    bannerStyles(bodyItem) {
      // fetch email from login
      // let user_email = localStorage.getItem("user_email");
      let status = bodyItem.Estado;
      // if(status == "En atención"){
      //   if(user_email != bodyItem.Turno?.user_call) status = "En atención Otro";
      // }

      const result = {
        "No asistió": {
          styles: "background-color: #FCEAEA; color: #EC5C5C;",
        },
        Ausente: {
          styles: "background-color: #EAFCFB; color: #1BCAC0;",
          icon: "#1BCAC0",
        },
        Atendido: {
          styles: "background-color: #E8EEFC; color: #5F83F1;",
        },
        "En atención": {
          styles: "background-color: #E2F3EB; color: #30B365;",
          icon: "#30B365",
        },
        "SIN TURNO": {
          styles: "background-color: #E8EEFC; color: rgb(186 194 219);",
        },
        "En atención Otro": {
          styles: "background-color: #E2F3EB; color: #30B365;",
        },
      };

      if (this.callData) {
        return status == "En atención"
          ? result[status]
          : result["SIN TURNO"] || {};
      }

      return result[status] || {};
    },

    bannerStylesWithoutCall(status) {
      const result = {
        "No asistió": {
          styles: "background-color: #FCEAEA; color: #EC5C5C;",
        },
        Ausente: {
          styles: "background-color: #EAFCFB; color: #1BCAC0;",
          icon: "#1BCAC0",
        },
        Atendido: {
          styles: "background-color: #E8EEFC; color: #5F83F1;",
        },
        "En atención": {
          styles: "background-color: #E2F3EB; color: #30B365;",
          icon: "#30B365",
        },
        "SIN TURNO": {
          styles: "background-color: #E8EEFC; color: rgb(186 194 219);",
        },
      };

      return result[status] || {};
    },

    disabledText(status) {
      return (
        (status === "No asistió" || status === "Atendido") &&
        "Table__Body--text_disabled"
      );
    },

    async onClickStatus(event, bodyItem) {
      console.log("onClickStatus")
      console.log(bodyItem)
      // fetch email from login
      // let user_email = localStorage.getItem("user_email");

      // if(bodyItem.Estado == "En atención"){
      //   if(user_email != bodyItem.Turno?.user_call) return;
      // }

      event.stopPropagation();

      const timer = setTimeout(() => {
        const element = document.getElementById(bodyItem.id);

        if (element) {
          element.scrollIntoView(false);
        }
        clearTimeout(timer);
      }, 100);

      switch (bodyItem.Estado) {
        case "Ausente":
          this.disabledAll = false;
          this.clearAllDropdowns();
          if (!bodyItem.showDropdown) {
            // If is not appoinment
            if (
              !bodyItem.Servicio ||
              (bodyItem.Servicio && bodyItem.Servicio == "")
            ) {
              this.onChangeTurnsHandler(bodyItem, (bodyItem2) => ({
                showDropdown: !bodyItem2.showDropdown,
              }));
            } else {
              this.onChangeBodyHandler(bodyItem, (bodyItem2) => ({
                showDropdown: !bodyItem2.showDropdown,
              }));
            }
          }
          break;
        case "Llamar": {
          // If is not appoinment
          if (
            !bodyItem.Servicio ||
            (bodyItem.Servicio && bodyItem.Servicio == "")
          )
            this.onChangeTurnsHandler(bodyItem, () => ({ loading: true }));
          else this.onChangeBodyHandler(bodyItem, () => ({ loading: true }));

          // fetch polling data
          await this.pollingWorkplaceGetAction();
          
          let group = this.pollingData.callableQueueGroups
            .filter((g) => g.turns.length > 0)
            .find((t) =>
              t.turns.map((t) => t.id).includes(bodyItem.Turno.turn_id)
            );

          // call user
          await this.callTurnActionById({
            "queueGroup.id": group?.id,
            "turn.id": bodyItem.Turno.turn_id,
            "waitingRoom.id": bodyItem.Turno.waiting_room_id,
          });
          
          this.$emit("finished");

          this.disabledAll = true;
          const timer = setTimeout(() => {
            // If is not appoinment
            if (
              !bodyItem.Servicio ||
              (bodyItem.Servicio && bodyItem.Servicio == "")
            )
              this.onChangeTurnsHandler(bodyItem, () => ({ loading: true }));
            else this.onChangeBodyHandler(bodyItem, () => ({ loading: false }));
            this.disabledAll = false;
            this.onCleanBody();
            clearTimeout(timer);
          }, 2000);
          break;
        }
        case "En atención":
          this.disabledAll = false;
          this.clearAllDropdowns();
          if (!bodyItem.showDropdown) {
            // If is not appoinment
            if (
              !bodyItem.Servicio ||
              (bodyItem.Servicio && bodyItem.Servicio == "")
            ) {
              this.onChangeTurnsHandler(bodyItem, (bodyItem2) => ({
                showDropdown: !bodyItem2.showDropdown,
              }));
            } else {
              this.onChangeBodyHandler(bodyItem, (bodyItem2) => ({
                showDropdown: !bodyItem2.showDropdown,
              }));
            }
          }
          break;
        default:
          return;
      }
    },

    clearAllDropdowns() {
      // Appointments
      const copyBody = JSON.parse(JSON.stringify(this.body));
      const copyBodyMaped = copyBody.map((item) => ({
        ...item,
        showDropdown: false,
      }));

      this.onChangeBody(copyBodyMaped);

      // turns
      const copyturns = JSON.parse(JSON.stringify(this.turns));
      const copyTurnsMaped = copyturns.map((item) => ({
        ...item,
        showDropdown: false,
      }));

      this.onChangeTurns(copyTurnsMaped);
    },

    onCleanBody(filter) {
      // Appointments
      const copyBody = JSON.parse(JSON.stringify(this.body));

      const copyBodyMaped = copyBody.map((item) => {
        if (filter && filter.id === item.id) {
          return filter;
        }

        return {
          ...item,
          showDropdown: false,
        };
      });

      this.onChangeBody(copyBodyMaped);

      // turns
      const copyTurns = JSON.parse(JSON.stringify(this.turns));

      const copyturnsMaped = copyTurns.map((item) => {
        if (filter && filter.id === item.id) {
          return filter;
        }

        return {
          ...item,
          showDropdown: false,
        };
      });

      this.onChangeTurns(copyturnsMaped);
    },

    onChangeBodyHandler(bodyItem, newValues) {
      const copyBody = JSON.parse(JSON.stringify(this.body));
      const copyIndex = copyBody.findIndex(
        (cbItem) => cbItem.id === bodyItem.id
      );
      const bodyItem2 = this.body.find((cbItem) => cbItem.id === bodyItem.id);

      copyBody[copyIndex] = {
        ...bodyItem2,
        ...newValues(bodyItem2),
      };

      this.onChangeBody(copyBody);
    },

    onChangeTurnsHandler(bodyItem, newValues) {
      const copyBody = JSON.parse(JSON.stringify(this.turns));
      const copyIndex = copyBody.findIndex(
        (cbItem) => cbItem.id === bodyItem.id
      );
      const bodyItem2 = this.turns.find((cbItem) => cbItem.id === bodyItem.id);

      copyBody[copyIndex] = {
        ...bodyItem2,
        ...newValues(bodyItem2),
      };

      this.onChangeTurns(copyBody);
    },

    onChangeLoading(isAtt, item, val) {
      if (val) {
        this.disabledAll = true;
      } else {
        this.disabledAll = false;
      }

      const original = isAtt ? this.buttonsAtention : this.buttonsAbsent;
      const copyArray = JSON.parse(JSON.stringify(original));
      const itemFoundIndex = original.findIndex(
        (itemLocal) => itemLocal.id === item.id
      );
      const action = isAtt
        ? this.onChangeButtonsAtention
        : this.onChangeButtonsAbsent;
      const itemFound = original.find((itemLocal) => itemLocal.id === item.id);

      copyArray[itemFoundIndex] = {
        ...itemFound,
        loading: val,
      };

      action(copyArray);
    },

    onChangeShowModal(event) {
      event.stopPropagation();

      this.showModal = false;
    },
    onCloseModalDerivate() {
      this.showModalDerivate = false
    },
    onChangeShowModalDerivate() { 
      this.showModalDerivate = true
    },
    
    onChangeFinishCall(finishCallType) {
      this.handleFinishCall(finishCallType);
    },

    async absentButtonsHandler(item, bodyItem2) {
      switch (item.Estado) {
        case "Llamar": {
          this.onChangeLoading(false, item, true);

          // fetch polling data
          await this.pollingWorkplaceGetAction();

          // call user
          await this.callTurnActionById({
            "queueGroup.id": bodyItem2.Turno.caller_group_id,
            "turn.id": bodyItem2.Turno.turn_id,
            "waitingRoom.id": bodyItem2.Turno.waiting_room_id,
          });

          this.$emit("finished");

          const timer = setTimeout(() => {
            this.onChangeLoading(false, item, false);
            this.onCleanBody();
            clearTimeout(timer);
          }, 2000);
          break;
        }
        case "No asistió":
          this.showModal = true;
          break;
      }
    },

    async atentionButtonsHandler(item) {
      this.selectedEstado = item.Estado;
     
      switch (item.Estado) {
        case "Derivar": {
          this.onChangeLoading(true, item, true);
          await this.handlerDerivableQueueGroups();
          this.$refs.deriveModal.open();
          this.onChangeLoading(true, item, false);
          //await this.reloadLoggedUserAction()
          this.$emit("finished");
          break;
        }
        case "Volver a llamar": {
          this.onChangeLoading(true, item, true);
          await this.reCallTurnAction();
          this.onChangeLoading(true, item, false);
          this.onCleanBody();
          this.$emit("finished");
          // const timer = setTimeout(() => {
          //   this.onChangeLoading(true, item, false);
          //   // this.onChangeBodyHandler(bodyItem, (bodyItem2) => ({
          //   //   Estado: !bodyItem2.showDropdown,
          //   // }));
          //   this.onCleanBody();
          //   clearTimeout(timer);
          // }, 4000);

          break;
        }
        case "Ausente": {
          await this.onChangeFinishCall("absent");
          this.onChangeLoading(true, item, false);
          this.onCleanBody();
          this.$emit("finished");
          // this.onChangeLoading(true, item, true);
          // const timer = setTimeout(() => {
          //   this.onChangeLoading(true, item, false);
          //   this.onCleanBody();
          //   clearTimeout(timer);
          // }, 4000);
          break;
        }
        case "No asistió":
          this.showModal = true;
          this.finishCallType = "revoke";
          break;
        case "Atendido":
          this.showModal = true;
          this.finishCallType = true;
          break;
      }

      this.showDrop = false
      
    },

    toAMPMDate(hour, minutes) {
      return moment()
        .hour(hour)
        .minutes(minutes || 0)
        .format("hh:mma");
    },
    toDate(date) {
      return moment(date)
        .format("hh:mma");
    },
    handleFinishCall(event) {
      if (!event) {
        this.options = [];
        if (this.loggedData.worker?.actualTurn) {
          if (
            this.loggedData.worker.actualTurn.queue
            // &&
            // this.loggedData.worker.configs.typifyServiceEnabled
          ) {
            if (this.loggedData.worker.actualTurn.queue.services) {
              this.showTypify = true;
              this.options =
                this.loggedData.worker.actualTurn.queue.services || [];
            }
          }
        } else {
          if (this.callData?.queue) {
            this.showTypify = true;
            this.options = this.callData.queue?.services || [];
          }
        }
        if (this.options.length > 0) return;
      }
      return this.finishCallData(event);
    },

    async finishCallData(evaluator, service_id) {
      this.loadingFinish = true;
      if (evaluator === "absent") await this.absentAction();
      else if (evaluator === "revoke") await this.revokeTurnAction();
      else await this.finishTurnAction(service_id);
      await new Promise((p) => {
        setTimeout(() => {
          p();
        }, 3000);
      });
      this.showModal = false;
      this.loadingFinish = false;
      this.resetCallData();
      // this.clearAllDropdowns();
      await this.reloadLoggedUserAction()
      this.$emit("finished");
    },

    checkExtra(extra, prop){
      if(!extra) return;
      try{
        let ex = JSON.parse(extra);
        ex = ex[0]?.Videollamada;
        var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
        let mex = ex.match(urlRegex);
        let params = new URLSearchParams(mex[0]);
        let pr = params.get('pr');
        let pr_b = atob(pr.replace('"', ''));
        let pr_f = decodeURIComponent(pr_b);
        let jsonExtra = JSON.parse(pr_f);
        if(prop == 'NumeroID'){
          return `${jsonExtra.ti} ${jsonExtra.nu}`
        }
        if(prop == 'Nombre'){
          return jsonExtra.na ? `${jsonExtra.na} ${jsonExtra.la}` : 'N/R';
        }
        return jsonExtra[prop] || 'N/R';

      } catch (e){
        console.error(e)
        return "";
      }
    }
  },
};
</script>

<style scoped>
.Table__Body {
  position: relative;
  padding: 0;
  grid-row: 2 / 3;
  display: grid;
  justify-items: start;
  grid-template-columns: 1fr;
  grid-template-rows: minmax(65px, 1fr);
  grid-auto-rows: minmax(65px, 1fr);
  border-bottom: 1px solid rgba(44, 66, 108, 0.6);

  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
}

.Table__Body:last-child {
  border: none;
}

.Table__Body--Item {
  position: relative;
  display: grid;
  justify-items: start;
  align-items: center;
  gap: 10px;
  grid-template-columns:
    repeat(2, var(--grid-minmax))
    minmax(250px, 350px) repeat(5, var(--grid-minmax)); /* TODO: 3. Change me to 8 and enable SMS column */
  grid-template-rows: 1fr;
  border-bottom: 1px solid #eff1f5;
  list-style: none;
  width: 100%;
}

.nameText {
  text-transform: capitalize;
}

.cedulaText {
  text-transform: lowercase;
}

.Banner {
  background-color: #fcf2e9;
  color: #f5a462;
  padding: 5px 10px;
  border-radius: 4px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
}

.IconBanner {
  margin-left: 6px;
  transition: 0.5s transform;
}

.IconBannerRotated {
  transform: rotate(-180deg);
}

.Banner:hover {
  opacity: 0.8;
}

.Table__Body--text_disabled {
  opacity: 0.5;
}

.starHour {
  position: absolute;
  /* left: -50.44px; */
  left: -70px;
  top: -10px;
  font-weight: 500;
  font-size: 15px;
  line-height: 21px;
}

.buttonSendSMS {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-panel-secondary);
  border-radius: 4px;
}

.ContainerDropdown {
  position: relative;
}

.DropdownBox {
  width: 247px;
  position: absolute;
  z-index: 10;
  left: 0;
  bottom: -62px;
  background: #ffffff;
  z-index: 900005;
  padding: 15px;
  filter: drop-shadow(3px 9px 9px rgba(0, 0, 0, 0.3));
  border-radius: 4px;
  transition: 0.25s all;
}

.buttonSendSMS:disabled {
  opacity: 35%;
}

.Table__Body--text {
  margin: 0;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  /* white-space: nowrap; */
}

.AttDropdown {
  bottom: -252px;
}

.AbsentDropdown {
  /* bottom: -158px; */
  bottom: -106px;
}

.ButtonDropdown {
  margin-bottom: 16px;
}

.ButtonDropdown:last-child {
  margin-bottom: 9px;
}

.MiniSpinner__Container {
  margin-left: 5px;
}

.noFound {
  width: 100%;
  text-align: center;
  padding: 40px;
}
</style>
